import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from "../components/layout";
import Section1 from "../sections/contact-us-section1";
import Section2 from "../sections/contact-us-section2";
import SEO from "../components/seo"

export default function Home() {
  return (
    <Layout 
      LogoColor={props => props.theme.colors.white} 
      HeaderMenuColor={props => props.theme.colors.white} 
      firstSectionBGColor={props => props.theme.colors.primary_violet}

      //button
      HeaderButtonColor={props => props.theme.buttons.secondary_small_button_white_violet.normal.color} 
      HeaderButtonBorder={props => props.theme.buttons.secondary_small_button_white_violet.normal.border_color} 
      HeaderButtonBackground={props => props.theme.buttons.secondary_small_button_white_violet.normal.background_color}
      
      HeaderButtonColorHover={props => props.theme.buttons.secondary_small_button_white_violet.hovered.color}
      HeaderButtonBorderHover={props => props.theme.buttons.secondary_small_button_white_violet.hovered.border_color} 
      HeaderButtonBackgroundHover={props => props.theme.buttons.secondary_small_button_white_violet.hovered.background_color}

      HeaderButtonColorPressed={props => props.theme.buttons.secondary_small_button_white_violet.pressed.color}
      HeaderButtonBorderPressed={props => props.theme.buttons.secondary_small_button_white_violet.pressed.border_color} 
      HeaderButtonBackgroundPressed={props => props.theme.buttons.secondary_small_button_white_violet.pressed.background_color}
    >
      <SEO title={"Bad-ass. Connected. Financially-independent."} description={"Build meaningful connections for women to achieve their professional and personal goals"} />
      
      <Section1></Section1>
      <Section2></Section2>
    </Layout>   
  )
}