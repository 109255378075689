import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { Link } from "gatsby"

const SectionBackground = styled.div`
  background-color: ${props => props.theme.colors.white};
  position:relative;
  width:100%;
  overflow:hidden;
  margin-top:-2px;
`
const SectionTitle = styled.div`
  font: 24px/32px Roboto;
  margin-bottom:20px;
`
const SectionItem = styled(Col)`
  margin-top:70px;
  margin-bottom:70px;
  text-align:center;
  display: inline-block;
  @media(max-width:767px){
    margin-top:30px;
    margin-bottom:30px;
  }
`
const SectionP = styled.p`
  font: 16px/28px Roboto;
  margin-bottom:20px;
`

const SectionEmail = styled.p`
  color: ${props => props.theme.colors.primary_violet};
  font: Bold 16px/28px Roboto;
  margin-bottom:20px;
`

const ColumnSection = styled(Col)`
  font: 20px/28px Roboto;
`

const ButtonFill6 = styled(Button)`
  height: 60px;
  color: ${props => props.theme.buttons.primary_button_violet.normal.color} !important;
  background-color: ${props => props.theme.buttons.primary_button_violet.normal.background_color} !important;
  border-color: ${props => props.theme.buttons.primary_button_violet.normal.border_color} !important;
  font: 16px/28px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.primary_button_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.colors.gray_shadow} !important;
    @media(min-width:1199px){
      margin-top:-3px;
      margin-bottom:3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:-3px;
      margin-bottom:3px;
    }
  }
  @media(max-width:767px){
    width:100%;
    margin-bottom:20px;
  }
`

export default function MasterclassSection2(props) {
  return (
  <SectionBackground>
    <Container>
      <Row>          
        <ColumnSection xs="12" md="4" xl="4">
            <SectionItem>
              <SectionTitle>
              Partnership Inqueries
              </SectionTitle>
              <SectionP>
              Email us for partnership inqueries, including marketing and partnership opportunities.
              </SectionP>
              <OutboundLink href="mailto:yours@womenworks.io">
                <SectionEmail>
                yours@womenworks.io
                </SectionEmail>
              </OutboundLink>
            </SectionItem>
          </ColumnSection>
          <ColumnSection xs="12" md="4" xl="4">
            <SectionItem css={css`
        @media(max-width:991px) {
          padding-top:32px;
        }
        @media(max-width:767px) {
          padding-top:0px;
        }
        `}>
              <SectionTitle>
              Support
              </SectionTitle>
              <SectionP>
              If you have any questions, you can fill the form above or email us directly to :
              </SectionP>
              <OutboundLink href="mailto:care@womenworks.io">
                <SectionEmail>
                  care@womenworks.io
                </SectionEmail>
              </OutboundLink>
            </SectionItem>
          </ColumnSection>
          {/* <Col xl="2" css={css`@media(max-width:1199px){display:none;}`}></Col> */}
          <ColumnSection xs="12" md="4" xl="4">
            <SectionItem>
              <SectionTitle>
              Helpful Resources
              </SectionTitle>
              <SectionP>
              Our FAQs probably help you while you are waiting for our response.
              </SectionP>
              <Link to="/faq/">
                  <ButtonFill6 variant="outline-light" css={css`@media(max-width:991px) {width:100%;}`}>
                      See our FAQs
                  </ButtonFill6>
              </Link>
            </SectionItem>
          </ColumnSection>
      </Row>
    </Container>
  </SectionBackground>
  )
}